import {
  type OptinToolStatisticsById,
  type OptinToolStatisticsResponse
} from '@ghostmonitor/recartapis'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

function getOptinToolStatistics(queryParams) {
  return request.get<OptinToolStatisticsResponse>('statistics/optin-tools', {
    params: queryParams
  })
}

export function useOptinToolsStat(): UseResource<OptinToolStatisticsById> & {
  prefetch: () => void
} {
  const queryClient = useQueryClient()
  const queryKey = [QUERY_KEY.optinToolsStat]

  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)

  const queryParams: {
    startDate?: string
    endDate?: string
  } = {}

  if (dateFrom) {
    const startDate = dateFrom.toISOString()
    queryParams.startDate = startDate
    queryKey.push(startDate)
  }

  if (dateTo) {
    const endDate = dateTo.toISOString()
    queryParams.endDate = endDate
    queryKey.push(endDate)
  }

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () => getOptinToolStatistics(queryParams),
    select: (response) => response.data,
    enabled: dateFrom !== undefined && dateTo !== undefined
  })

  function prefetchOptinToolStats() {
    queryClient.prefetchQuery({
      queryKey,
      queryFn: () => getOptinToolStatistics(queryParams)
    })
  }

  return {
    data,
    isLoading,
    prefetch: prefetchOptinToolStats
  }
}
