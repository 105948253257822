import { type BlastScheduleType, type SMSCampaignScheduleType } from '@ghostmonitor/recartapis'
import moment, { type Moment } from 'moment'
import * as momentTimeZone from 'moment-timezone'

export function loadScheduledDate(
  scheduledFor: string | null | undefined,
  scheduleType: SMSCampaignScheduleType | BlastScheduleType | undefined,
  timezone: string
): Moment {
  if (!scheduledFor) {
    return momentTimeZone.tz(new Date(), timezone).add(2, 'hours')
  }

  let scheduledForMoment = moment.tz(scheduledFor, timezone)

  if (scheduleType === 'subscriberTimezone') {
    const utcFromScheduledFor = moment(scheduledFor).utc()
    const loadingTime = moment.tz(
      {
        year: utcFromScheduledFor.year(),
        month: utcFromScheduledFor.month(),
        date: utcFromScheduledFor.date(),
        hour: utcFromScheduledFor.hour(),
        minute: utcFromScheduledFor.minute(),
        second: utcFromScheduledFor.second(),
        millisecond: utcFromScheduledFor.millisecond()
      },
      timezone
    )
    const offset = loadingTime.utcOffset()

    scheduledForMoment = scheduledForMoment.utc().subtract(offset, 'minutes').tz(timezone)
  }

  return scheduledForMoment
}

export function saveScheduledDate(
  scheduledFor: string | undefined,
  scheduleType: SMSCampaignScheduleType | BlastScheduleType,
  timezone?: string
): Moment {
  if (!timezone) {
    timezone = moment.tz.guess()
  }

  if (!scheduledFor) {
    return momentTimeZone.tz(new Date(), timezone).add(2, 'hours')
  }

  let scheduledForMoment = moment.tz(scheduledFor, timezone)

  if (scheduleType === 'subscriberTimezone') {
    const savingTime = moment(scheduledFor).tz(timezone)
    const offset = savingTime.utcOffset()

    scheduledForMoment = scheduledForMoment.utc().add(offset, 'minutes')
  }

  return scheduledForMoment
}
