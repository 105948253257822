import {
  type EditorField,
  type Popup,
  type PopupEmailSettings,
  type PopupMessengerSettings,
  type PopupOneClickOptinSettings,
  type PopupOneClickVerificationSettings,
  type PopupOneTimePasswordSettings,
  type PopupSMSSettings,
  type PopupSMSWithEmailSettings,
  type PopupSuccessSettings,
  type PopupTeaserSettings
} from '@ghostmonitor/recartapis'
import isPlainObject from 'lodash/isPlainObject'

type StepSettings =
  | PopupTeaserSettings
  | PopupEmailSettings
  | PopupMessengerSettings
  | PopupSMSSettings
  | PopupSMSWithEmailSettings
  | PopupOneTimePasswordSettings
  | PopupOneClickVerificationSettings
  | PopupOneClickOptinSettings
  | PopupSuccessSettings
  | undefined

function collectConnectedFields(stepSettings: StepSettings | EditorField, slug: string): string[] {
  const fields: string[] = []

  if (!stepSettings) {
    return fields
  }

  for (const [key, field] of Object.entries(stepSettings)) {
    if (!isPlainObject(field)) {
      continue
    }

    if (field.generalStyleSlug && field.generalStyleSlug !== slug) {
      continue
    }

    if (field.generalStyleSlug === slug) {
      fields.push(key)
      continue
    }

    const connectedFields = collectConnectedFields(field, slug)
    fields.push(...connectedFields.map((connectedField) => `${key}.${connectedField}`))
  }

  return fields
}

export function getConnectedFieldsToGeneralStyle(popup: Popup, slug: string | undefined): string[] {
  if (!slug) {
    return []
  }

  const fields: string[] = []

  for (const step of popup.steps) {
    const stepSettings = popup.settings[step]

    const connectedFields = collectConnectedFields(stepSettings, slug)
    fields.push(...connectedFields.map((connectedField) => `${step}.${connectedField}`))
  }

  return fields
}
