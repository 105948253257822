import type {
  EditorField,
  GeneralStyle,
  OptinToolShadow,
  Popup,
  PopupSettings
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { type GeneralStyleEditorField } from '../components/editor/right-panel/general-styles/utils'

export type ResolvableSettings =
  | PopupSettings['teaser']
  | PopupSettings['email']
  | PopupSettings['messenger']
  | PopupSettings['sms']
  | PopupSettings['smsWithEmail']
  | PopupSettings['oneTimePassword']
  | PopupSettings['oneClickVerification']
  | PopupSettings['oneClickOptin']
  | PopupSettings['success']
  | EditorField
  | OptinToolShadow
  | []
  | string
  | null

function getGeneralStyleBySlug(generalStyles: GeneralStyle[], slug: string): GeneralStyle {
  const generalStyle = generalStyles.find((style) => style.slug === slug)

  if (!generalStyle) {
    throw new Error(`General style with slug ${slug} not found`)
  }

  return generalStyle
}

function mergeFieldStyles(
  generalStyle: GeneralStyle,
  existingStyle: GeneralStyleEditorField,
  temporaryGeneralStyle?: GeneralStyle | null
): GeneralStyleEditorField {
  if (temporaryGeneralStyle && temporaryGeneralStyle.slug === existingStyle.generalStyleSlug) {
    const { slug, name, ...restTemporaryGeneralStyle } = temporaryGeneralStyle
    return merge({}, existingStyle, restTemporaryGeneralStyle)
  } else {
    const { slug, name, ...restGeneralStyle } = generalStyle
    return merge({}, restGeneralStyle, existingStyle)
  }
}

function resolveStylesForStep(
  field: ResolvableSettings,
  generalStyles: GeneralStyle[],
  temporaryGeneralStyle?: GeneralStyle | null
) {
  if (typeof field !== 'object' || field === null) {
    return field
  }

  let resolvedField = cloneDeep(field)

  if ('generalStyleSlug' in field && field.generalStyleSlug) {
    const generalStyle = getGeneralStyleBySlug(generalStyles, field.generalStyleSlug)
    resolvedField = mergeFieldStyles(
      generalStyle,
      resolvedField as GeneralStyleEditorField,
      temporaryGeneralStyle
    )
  }

  if (Array.isArray(resolvedField)) {
    return resolvedField.map((item) =>
      resolveStylesForStep(item, generalStyles, temporaryGeneralStyle)
    )
  }

  return Object.fromEntries(
    Object.entries(resolvedField).map(([key, value]) => [
      key,
      resolveStylesForStep(value, generalStyles, temporaryGeneralStyle)
    ])
  )
}

export function resolveStyles(popup: Popup, temporaryGeneralStyle?: GeneralStyle | null): Popup {
  const { generalStyles } = popup.settings
  const resolvedSettings = cloneDeep(popup.settings)

  for (const step of popup.steps) {
    if (popup.settings[step]) {
      resolvedSettings[step] = resolveStylesForStep(
        popup.settings[step],
        generalStyles,
        temporaryGeneralStyle
      )
    }
  }

  return { ...popup, settings: resolvedSettings }
}
