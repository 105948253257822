import {
  type DiscountCodePool,
  type GetDiscountCodePoolResponse,
  type GetDiscountCodePoolsResponse,
  type UpdateDiscountCodePoolRequest,
  type UpdateDiscountCodePoolResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'
import type { UseQueryOptions } from '@tanstack/react-query/src/types'

export type UpdateDiscountCodePoolTargetCountRequestUI = Omit<
  UpdateDiscountCodePoolRequest,
  'siteId' | 'discountCodePoolId'
>

export function useDiscountCodePool(
  discountCodePoolId?: string,
  options?: UseQueryOptions<GetDiscountCodePoolResponse>
): UseResource<DiscountCodePool> {
  const queryClient = useQueryClient()
  const queryKey = [QUERY_KEY.discountCodePool, discountCodePoolId]

  const { isInitialLoading, data, error, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<GetDiscountCodePoolResponse>(`discount-code-pools/${discountCodePoolId}`)
    },
    enabled: discountCodePoolId !== undefined,
    ...options
  })

  const { mutateAsync: updateDiscountCodePool, isLoading: isUpdateDiscountCodePoolLoading } =
    useMutation<
      UpdateDiscountCodePoolResponse,
      unknown,
      UpdateDiscountCodePoolTargetCountRequestUI
    >({
      mutationFn: (discountCodePool: UpdateDiscountCodePoolTargetCountRequestUI) => {
        return request.patch<UpdateDiscountCodePoolResponse>(
          `discount-code-pools/${discountCodePoolId}`,
          discountCodePool
        )
      },
      onSuccess: (updateDiscountCodePoolTargetCountResponse: UpdateDiscountCodePoolResponse) => {
        const discountCodePools: GetDiscountCodePoolsResponse = queryClient.getQueryData([
          QUERY_KEY.discountCodePools
        ])
        if (discountCodePools) {
          const newDiscountCodePools = {
            data: discountCodePools.data.map((discountCodePool) =>
              discountCodePool.id === discountCodePoolId
                ? updateDiscountCodePoolTargetCountResponse.data
                : discountCodePool
            )
          }
          queryClient.setQueryData([QUERY_KEY.discountCodePools], newDiscountCodePools)
          queryClient.invalidateQueries([QUERY_KEY.discountCodePools])
        }
        queryClient.invalidateQueries(queryKey)
      }
    })

  return {
    isLoading: isInitialLoading,
    data: data?.data,
    error,
    refetch: refetch as any,
    patch: updateDiscountCodePool,
    isUpdateDiscountCodePoolLoading
  }
}
