import { useSelector } from 'react-redux'
import { type UseResource } from '../../../../hooks/types/use-resource.type'
import { flowEditorSelectors } from '../../../../store/selectors'
import { type SMSCampaignStatus } from '@ghostmonitor/recartapis'
import { useSMSCampaign } from '../../../../hooks/resources/use-sms-campaign'

// This hook is meant to be used in Flow Editor to poll SMS Campaign status
// In lists, use useSMSCampaigns instead
export function useSMSCampaignStatus(
  smsCampaignId: string,
  refetchInterval = 3000,
  isEnabled = true
): UseResource<SMSCampaignStatus> {
  const isEverSaved = useSelector(flowEditorSelectors.selectEditorFlowMeta)?.isEverSaved
  const { isLoading, data: smsCampaign } = useSMSCampaign(smsCampaignId, {
    refetchInterval,
    enabled: smsCampaignId !== undefined && isEverSaved === true && isEnabled
  })

  return {
    data: smsCampaign?.status,
    isLoading
  }
}
