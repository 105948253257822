import { formatters } from '../../../utils/formatters/formatters'
import { quietHoursTimeFormat } from '../../../utils/utils'
import { type SMSSettings } from '@ghostmonitor/recartapis'

export const enum SchedulingType {
  NOW = 'NOW',
  LATER = 'LATER'
}

export const quietHoursTooltipText =
  'Quiet hours prevent subscribers from receiving promotional text messages late night or early morning. Texts will be queued and sent once quiet hours are over.'

export function getFormattedQuietHoursRange(
  smsQuietHoursConfig: SMSSettings.QuietHoursConfig | undefined,
  isStartCapitalize = true
): string {
  if (!smsQuietHoursConfig) {
    return ''
  }

  const range = `from ${formatters.date(
    smsQuietHoursConfig.start ?? '',
    quietHoursTimeFormat
  )} to ${formatters.date(smsQuietHoursConfig.end ?? '', quietHoursTimeFormat)} in subscriber's timezone`
  return isStartCapitalize ? range.charAt(0).toUpperCase() + range.slice(1) : range
}
