import { SequenceTag } from '@ghostmonitor/recartapis'
import { sortRecentCampaignFlowsItemByDate } from '../../routes/home/components/recent-campaign-flows/components/utils/sort-campaign-flow-items-by-date'
import { selectCurrencySlug } from '../../store/slices/site/site.selectors'
import { type RecentCampaignFlowsListItem } from '../../types/recent-campaign-flows-list-item/recent-campaign-flows-list-item.type'
import { SMSCampaignStatusLabel } from '../../types/sms-campaign/sms-campaign-status-label'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'
import { isSMSCampaignFlowUI } from '../../types/guards/flow-ui.guards'

export function useRecentCampaignFlowsList(): UseResource<RecentCampaignFlowsListItem[]> {
  const recentCampaignFlowTags = [SequenceTag.CAMPAIGN, SequenceTag.SMS]
  const { isLoading: sequencesIsLoading, data: flows } =
    hooks.useFlowsByTags(recentCampaignFlowTags)
  const { data: smsCampaigns, isLoading: isSMSCampaignsLoading } = hooks.useSMSCampaigns()
  const currencySlug = hooks.useSiteSelector(selectCurrencySlug)

  const { data: sequencesStat, isLoading: sequencesStatsIsLoading } = hooks.useSequencesStat({
    isEnabled: true,
    tags: recentCampaignFlowTags
  })

  let recentCampaignFlowListItems: RecentCampaignFlowsListItem[] = []

  if (
    !sequencesIsLoading &&
    !isSMSCampaignsLoading &&
    !sequencesStatsIsLoading &&
    currencySlug !== undefined
  ) {
    flows.forEach((flow) => {
      const sequenceStat = sequencesStat[flow._id]
      if (!sequenceStat) {
        return
      }

      if (isSMSCampaignFlowUI(flow)) {
        const smsCampaign = smsCampaigns.find((blast) => blast.sequenceId === flow._id)
        const shouldShowCampaign =
          smsCampaign?.status === 'sent' || smsCampaign?.status === 'sending'
        if (!shouldShowCampaign) {
          return
        }
        recentCampaignFlowListItems.push({
          _id: smsCampaign.sequenceId,
          name: flow.name,
          channel: 'sms',
          scheduleType: smsCampaign.scheduleType,
          tags: flow.tags,
          createdAt: flow.createdAt,
          updatedAt: flow.updatedAt,
          status: SMSCampaignStatusLabel[smsCampaign.status],
          startDate: smsCampaign.scheduledFor,
          endDate: null,
          stats: {
            spend: sequenceStat.cost.sms[currencySlug] ?? 0,
            roi: sequenceStat.roi ?? 0,
            sent: sequenceStat.sent ?? 0,
            sales: sequenceStat?.salesTotal[currencySlug] ?? 0,
            recipient: sequenceStat?.recipient ?? 0
          }
        })
      }
    })

    recentCampaignFlowListItems.sort(sortRecentCampaignFlowsItemByDate)

    recentCampaignFlowListItems = recentCampaignFlowListItems.slice(0, 3)
  }

  return {
    data: sequencesStat && recentCampaignFlowListItems,
    isLoading: sequencesIsLoading || sequencesStatsIsLoading || isSMSCampaignsLoading
  }
}
