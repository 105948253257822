import { type FlowUI, type HttpResponse, SequenceTag } from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from '../../components/app/query-client'
import { api } from '../../utils/api'
import { request } from '../../utils/request'
import { type PatchResourceArgs, type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'

type PatchFlowPayload = Pick<FlowUI, 'name'>

async function patch({ id, payload }: PatchResourceArgs<PatchFlowPayload>) {
  await api.patchFlow(id, payload)
}

export function useFlows(enabled?: boolean): UseResource<FlowUI[], Partial<FlowUI>> & {
  handlePatchMutate: (args: PatchResourceArgs<PatchFlowPayload>) => void
} {
  const queryKey = [QUERY_KEY.flows]
  const { handleSettled, handleError } = useMutationHelpers(queryKey)

  const {
    isLoading,
    data: flows,
    isError,
    refetch
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await request.get<HttpResponse<FlowUI[]>>('flows', {
        params: { tags: SequenceTag.SMS, without_sequence_items: true, omit_diagrams: true }
      })
      return response.data
    },
    enabled
  })

  const handlePatchMutate = useCallback((patchPayload: PatchResourceArgs<PatchFlowPayload>) => {
    queryClient.cancelQueries(queryKey)

    const flowsQuery = queryClient.getQueryCache().find(queryKey)
    const previousFlows = cloneDeep(flowsQuery.state.data as FlowUI[])
    const flows = cloneDeep(flowsQuery.state.data as FlowUI[])
    const previousFlow = flows.find((flow) => flow._id === patchPayload.id)

    if (!previousFlow) {
      return
    }

    previousFlow.name = patchPayload.payload.name
    queryClient.setQueryData(queryKey, flows)

    return () => queryClient.setQueryData(queryKey, previousFlows)
  }, [])

  const { mutateAsync: mutatePatchFlow } = useMutation<
    void,
    unknown,
    PatchResourceArgs<PatchFlowPayload>
  >({
    mutationFn: patch,
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handleError
  })

  return {
    data: flows,
    isLoading,
    isError,
    refetch: refetch as any,
    patch: mutatePatchFlow,
    handlePatchMutate
  }
}
