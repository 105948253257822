import { useSelector } from 'react-redux'
import { ShimmerRow } from '../../../../components/facebook-account-list/shimmer-row/shimmer-row.component'
import { hooks } from '../../../../hooks/hooks'
import { selectCurrencySlug } from '../../../../store/selectors'
import { formatters } from '../../../../utils/formatters/formatters'
import { type BaseSequenceItemNodeModel } from '../../models/sequence-item/base-sequence-item-model'
import { Stat } from '../stat/stat.component'

export interface SequenceItemStatsBarProps {
  sequenceItemNode: BaseSequenceItemNodeModel
}

export function SequenceItemStatsBar(props: SequenceItemStatsBarProps) {
  const sequenceItemId = props.sequenceItemNode.sequenceItemId
  const {
    data: sequenceItemStat,
    isError: isSequenceItemStatLoadingError,
    isLoading: isSequenceItemsStatLoading
  } = hooks.useEditorSequenceItemStat(sequenceItemId)
  const currencySlug = useSelector(selectCurrencySlug)

  if (isSequenceItemsStatLoading) {
    return <ShimmerRow />
  }

  if (isSequenceItemStatLoadingError) {
    return null
  }

  const sales = sequenceItemStat.salesTotal[currencySlug] ?? 0
  const openRate =
    sequenceItemStat.sent > 0 && sequenceItemStat.sentToPSID === 0
      ? {
          value: '?',
          tooltip: 'Open rate not available due to Facebook policy'
        }
      : { value: formatters.percentage(sequenceItemStat.openRate) }

  return (
    <>
      <Stat key='sent' value={formatters.number(sequenceItemStat.sent)} label='sent' />
      <Stat key='open-rate' value={openRate.value} label='open rate' tooltip={openRate.tooltip} />
      <Stat
        key='click-rate'
        value={formatters.percentage(sequenceItemStat.clickRate)}
        label='link CTR'
        title='link click rate'
      />
      <Stat key='sales' value={formatters.currency(Math.ceil(sales), currencySlug)} label='sales' />
    </>
  )
}
