import type {
  CustomerChatSettings,
  Device,
  EmbeddedForm,
  EmbeddedFormSettings,
  GeneralStyle,
  KeywordSettings,
  LandingPage,
  LandingPageSettings,
  OptinTool,
  OptinToolStep,
  Popup,
  PopupSettings
} from '@ghostmonitor/recartapis'
import { createSelector } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { type OptinToolMenuItem } from '../../../routes/optin-tools/components/editor/left-panel/left-panel.component'
import { getCopiedFieldName, isOneClickOptinTool } from '../../../routes/optin-tools/utils/helpers'
import { resolveStyles } from '../../../routes/optin-tools/utils/style-resolver'
import type {
  AccordionError,
  InputState,
  MenuItemError,
  OptinToolEditorState,
  OptinToolMeta,
  OptinToolMetaErrorField
} from './optin-tool-editor.reducer'

export const selectTemporaryGeneralStyle = (
  state: OptinToolEditorState<OptinTool>
): OptinToolMeta['temporaryGeneralStyle'] => {
  return state.optinToolMeta.temporaryGeneralStyle
}

export const selectOptinTool = <T extends OptinTool>(state: OptinToolEditorState<T>): T =>
  state.optinTool as T

export const selectResolvedPopup = createSelector(
  selectOptinTool,
  selectTemporaryGeneralStyle,
  (optinTool, temporaryGeneralStyle): Popup =>
    resolveStyles(optinTool as Popup, temporaryGeneralStyle)
)

export const selectOptinToolSteps = <T extends Popup | LandingPage | EmbeddedForm>(
  state: OptinToolEditorState<T>
): OptinToolStep[] => state.optinTool!.steps

export const selectIsOneClickOptinTool = <T extends Popup | LandingPage | EmbeddedForm>(
  state: OptinToolEditorState<T>
): boolean => isOneClickOptinTool(state.optinTool!.steps)

export const selectOptinToolSettings = (
  state: OptinToolEditorState<OptinTool>
):
  | PopupSettings
  | CustomerChatSettings
  | KeywordSettings
  | LandingPageSettings
  | EmbeddedFormSettings => state.optinTool!.settings

export const selectOptinToolSettingsField = <T>(
  state: OptinToolEditorState<OptinTool>,
  fieldName: string
): T => {
  return get(state.optinTool!.settings, fieldName)
}

export const selectOptinToolSettingsCopiedField = <T>(
  state: OptinToolEditorState<OptinTool>,
  copiedFieldName: string
): T => {
  const field = get(state.optinTool!.settings, copiedFieldName)
  if (field?.type !== 'copied') {
    return field
  } else {
    const newCopiedFieldName = getCopiedFieldName(copiedFieldName, field.copyOf)
    return get(state.optinTool!.settings, newCopiedFieldName)
  }
}

export const selectOptinToolDevices = (state: OptinToolEditorState<OptinTool>): string[] =>
  state.optinTool!.devices

export const selectOptinToolSequenceId = (state: OptinToolEditorState<OptinTool>): string | null =>
  (state.optinTool!.settings as PopupSettings).sequenceId

export const selectOptinToolMeta = (state: OptinToolEditorState<OptinTool>): OptinToolMeta =>
  state.optinToolMeta

export const selectOptinToolActiveMenuItem = (
  state: OptinToolEditorState<OptinTool>
): OptinToolMenuItem | null => state.optinToolMeta.activeMenuItem

export const selectOptinToolActivePreviewDevice = (
  state: OptinToolEditorState<OptinTool>
): Device => state.optinToolMeta.activePreviewDevice!

export const selectOptinToolInputState = (state: OptinToolEditorState<OptinTool>): InputState =>
  state.optinToolMeta.inputState!

export const selectOptinToolMenuItems = (
  state: OptinToolEditorState<OptinTool>
): OptinToolMenuItem[] => state.optinToolMeta.menuItems

export const selectOptinToolErrorByField = (
  state: OptinToolEditorState<OptinTool>,
  view: string,
  accordionSlug: string,
  fieldName: string
): OptinToolMetaErrorField | undefined => {
  return state.optinToolMeta.errors?.[view]?.[accordionSlug]?.[fieldName] as OptinToolMetaErrorField
}

export function selectOptinToolErrorMeta(
  state: OptinToolEditorState<OptinTool>,
  menuSlug: string,
  accordionSlug: string,
  fieldName: string
): OptinToolMetaErrorField
export function selectOptinToolErrorMeta(
  state: OptinToolEditorState<OptinTool>,
  menuSlug: string,
  accordionSlug: string
): AccordionError
export function selectOptinToolErrorMeta(
  state: OptinToolEditorState<OptinTool>,
  menuSlug: string
): MenuItemError
export function selectOptinToolErrorMeta(
  state: OptinToolEditorState<OptinTool>,
  menuSlug: string,
  accordionSlug?: string,
  fieldName?: string
): OptinToolMetaErrorField | OptinToolMetaErrorField[] | AccordionError | MenuItemError {
  if (accordionSlug && fieldName) {
    return state.optinToolMeta.errors?.[menuSlug]?.[accordionSlug]?.[fieldName]
  } else if (accordionSlug) {
    return state.optinToolMeta.errors?.[menuSlug]?.[accordionSlug] as AccordionError
  } else {
    return state.optinToolMeta.errors?.[menuSlug] as MenuItemError
  }
}

export const selectHasOptinToolErrorOnAccordion = createSelector(
  [selectOptinToolMeta, (state, menuSlug, accordionSlug) => ({ menuSlug, accordionSlug })],
  (optinToolMeta, { menuSlug, accordionSlug }) => {
    const [_, index] = accordionSlug.split('-')
    const errors = accordionSlug.startsWith('formItem')
      ? optinToolMeta.errors?.[menuSlug]?.form.items
      : optinToolMeta.errors?.[menuSlug]?.[accordionSlug]
    if (errors) {
      if (Array.isArray(errors)) {
        return errors[index] && errors[index]?.errorMessage !== ''
      }
      return Object.values(errors).some((error) => error.errorMessage !== '')
    }

    return false
  }
)

export const selectIsPreviewInFullscreen = (state: OptinToolEditorState<OptinTool>) =>
  state.optinToolMeta.isPreviewInFullscreen

export const selectRecentlyUsedFontTypes = (
  state: OptinToolEditorState<OptinTool>
): OptinToolMeta['recentlyUsedFontTypes'] => {
  return state.optinToolMeta.recentlyUsedFontTypes
}

export const selectPopupGeneralStyle = <T extends GeneralStyle>(
  state: OptinToolEditorState<Popup>,
  slug: string | null
): T | null => {
  if (slug === null) {
    return null
  }

  return state.optinTool!.settings.generalStyles.find(
    (generalStyle) => generalStyle.slug === slug
  ) as T
}
