import type { OptinToolStep, Popup } from '@ghostmonitor/recartapis'
import { useMemo } from 'react'
import { useIsImageLoading } from './use-is-image-loading'

interface UsePreloadImagesReturnType {
  hasAllImagesLoaded: boolean
}

export function usePreloadPopupImages(popup: Popup | undefined): UsePreloadImagesReturnType {
  const logoGeneralStyleUrl = getGeneralStyleUrl('logo')

  const backgroundImageUrl = popup?.settings.background?.image?.source
  const additionalImageUrl = popup?.settings.additionalImage?.image?.source

  const teaserLogoUrl = getLogoUrl('teaser')
  const emailLogoUrl = getLogoUrl('email')
  const smsLogoUrl = getLogoUrl('sms')
  const smsWithEmailLogoUrl = getLogoUrl('smsWithEmail')
  const messengerLogoUrl = getLogoUrl('messenger')
  const oneTimePasswordLogoUrl = getLogoUrl('oneTimePassword')
  const successLogoUrl = getLogoUrl('success')

  const teaserSecondaryImageUrl = getSecondaryImageUrl('teaser')
  const emailSecondaryImageUrl = getSecondaryImageUrl('email')
  const smsSecondaryImageUrl = getSecondaryImageUrl('sms')
  const smsWithEmailSecondaryImageUrl = getSecondaryImageUrl('smsWithEmail')
  const oneTimePasswordSecondaryImageUrl = getSecondaryImageUrl('oneTimePassword')
  const successSecondaryImageUrl = getSecondaryImageUrl('success')

  const { isLoading: isLogoGeneralStyleLoading } = useIsImageLoading(logoGeneralStyleUrl)

  const { isLoading: isBackgroundImageLoading } = useIsImageLoading(backgroundImageUrl)
  const { isLoading: isAdditionalImageLoading } = useIsImageLoading(additionalImageUrl)

  const { isLoading: isTeaserLogoLoading } = useIsImageLoading(teaserLogoUrl)
  const { isLoading: isEmailLogoLoading } = useIsImageLoading(emailLogoUrl)
  const { isLoading: isSMSLogoLoading } = useIsImageLoading(smsLogoUrl)
  const { isLoading: isMessengerLogoLoading } = useIsImageLoading(messengerLogoUrl)
  const { isLoading: isOneTimePasswordLogoLoading } = useIsImageLoading(oneTimePasswordLogoUrl)
  const { isLoading: isSMSWithEmailLogoLoading } = useIsImageLoading(smsWithEmailLogoUrl)
  const { isLoading: isSuccessLogoLoading } = useIsImageLoading(successLogoUrl)

  const { isLoading: isTeaserSecondaryImageLoading } = useIsImageLoading(teaserSecondaryImageUrl)
  const { isLoading: isEmailSecondaryImageLoading } = useIsImageLoading(emailSecondaryImageUrl)
  const { isLoading: isSMSSecondaryImageLoading } = useIsImageLoading(smsSecondaryImageUrl)
  const { isLoading: isSMSWithEmailSecondaryLoading } = useIsImageLoading(
    smsWithEmailSecondaryImageUrl
  )
  const { isLoading: isOneTimePasswordSecondaryImageLoading } = useIsImageLoading(
    oneTimePasswordSecondaryImageUrl
  )
  const { isLoading: isSuccessSecondaryImageLoading } = useIsImageLoading(successSecondaryImageUrl)

  const hasAllImagesLoaded = useMemo(() => {
    return [
      isLogoGeneralStyleLoading,
      isBackgroundImageLoading,
      isAdditionalImageLoading,
      isTeaserLogoLoading,
      isEmailLogoLoading,
      isSMSLogoLoading,
      isMessengerLogoLoading,
      isOneTimePasswordLogoLoading,
      isSMSWithEmailLogoLoading,
      isSuccessLogoLoading,
      isTeaserSecondaryImageLoading,
      isEmailSecondaryImageLoading,
      isSMSSecondaryImageLoading,
      isSMSWithEmailSecondaryLoading,
      isOneTimePasswordSecondaryImageLoading,
      isSuccessSecondaryImageLoading
    ].every((isLoading) => !isLoading)
  }, [
    isLogoGeneralStyleLoading,
    isBackgroundImageLoading,
    isAdditionalImageLoading,
    isTeaserLogoLoading,
    isEmailLogoLoading,
    isSMSLogoLoading,
    isMessengerLogoLoading,
    isOneTimePasswordLogoLoading,
    isSMSWithEmailLogoLoading,
    isSuccessLogoLoading,
    isTeaserSecondaryImageLoading,
    isEmailSecondaryImageLoading,
    isSMSSecondaryImageLoading,
    isSMSWithEmailSecondaryLoading,
    isOneTimePasswordSecondaryImageLoading,
    isSuccessSecondaryImageLoading
  ])

  function getGeneralStyleUrl(slug: string): string | undefined {
    const style = popup?.settings.generalStyles.find(
      (style) => style.type === 'image' && style.slug === slug
    )
    return style?.type === 'image' ? style.source : undefined
  }

  function getLogoUrl(step: OptinToolStep): string | undefined {
    return popup?.settings[step]?.logo?.type === 'image'
      ? popup.settings[step]?.logo?.source
      : undefined
  }

  function getSecondaryImageUrl(step: Exclude<OptinToolStep, 'messenger'>): string | undefined {
    return popup?.settings[step]?.secondaryImage?.type === 'image'
      ? popup.settings[step]?.secondaryImage?.source
      : undefined
  }

  return {
    hasAllImagesLoaded
  }
}
