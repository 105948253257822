import type {
  OptinTool,
  OptinToolListResponse,
  OptinToolListView,
  PatchOptinToolRequest,
  PatchSegmentRequest
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { showErrorModal } from '../../components/ui-kit/modals/error-modal/error-modal.component'
import { createScope } from '../../utils/logger/logger'
import { request } from '../../utils/request'
import {
  type PatchResourceArgs,
  type RemoveResourceArgs,
  type UseResource
} from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

const logger = createScope('dashboard')

async function patch({ id, payload }: PatchResourceArgs<PatchOptinToolRequest>) {
  await request.patch<void>(`optin-tools/${id}`, payload)
}

async function remove({ id }: RemoveResourceArgs) {
  await request.del<void>(`optin-tools/${id}`)
}

export function useOptinToolsList<T extends OptinTool = OptinTool>(filter?: {
  type: OptinTool['type']
}): UseResource<OptinToolListView<T>[]> {
  const queryClient = useQueryClient()
  const queryKey = useMemo(() => [QUERY_KEY.optinToolsList], [])
  const navigate = useNavigate()

  const { isLoading, data, isError, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<OptinToolListResponse<T>>('optin-tools/list')
    },
    select: (response) => {
      if (filter?.type) {
        return response.data.filter((optinTool) => optinTool.type === filter.type)
      }

      return response.data
    }
  })

  const handlePatchMutate = useCallback(
    async (patchPayload: PatchResourceArgs<PatchSegmentRequest>) => {
      await queryClient.cancelQueries(queryKey)
      const prevOptinToolList: OptinToolListResponse<T> = queryClient.getQueryData(queryKey)
      const optinToolList = prevOptinToolList.data.map((optinTool) =>
        optinTool.id === patchPayload.id
          ? {
              ...optinTool,
              ...patchPayload.payload
            }
          : optinTool
      )
      queryClient.setQueryData(queryKey, { data: optinToolList })

      return { prevOptinToolList }
    },
    [queryClient, queryKey]
  )

  const handleRemove = useCallback(
    (removePayload: RemoveResourceArgs) => {
      queryClient.cancelQueries(queryKey)

      const dataQuery = queryClient.getQueryCache().find(queryKey)
      const previousData = cloneDeep(dataQuery.state.data as OptinToolListResponse<T>)

      const newData: OptinToolListResponse<T> = {
        ...previousData,
        data: previousData.data.filter((item) => item.id !== removePayload.id)
      }

      queryClient.setQueryData(queryKey, newData)

      return () => queryClient.setQueryData(queryKey, previousData)
    },
    [queryClient, queryKey]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryKey)
  }, [queryClient, queryKey])

  const handlePatchError = useCallback(
    (err, newOptinTool, context) => {
      logger.error(err)

      queryClient.setQueryData(queryKey, context.prevOptinToolList)

      if (err.message === 'Cannot activate optin tool with empty geotargeting options') {
        showErrorModal({
          title: 'Opt-in tool cannot be activated',
          content: 'Please resolve all errors before you activate the opt-in tool.',
          okText: 'Open editor',
          // only sms popups/landing pages should run into this error
          onOk: () => {
            navigate(`editor/${newOptinTool.id}`)
          }
        })
      } else {
        showErrorModal({
          title: 'Failed to update optin tool.',
          content: 'Please try again or contact support.'
        })
      }
    },
    [queryClient, queryKey]
  )

  const handleRemoveError = useCallback((err, _, rollback: () => void) => {
    logger.error(err)
    rollback()
    showErrorModal({
      title: 'Removing optin tool failed',
      content: 'Please try again or contact support.'
    })
  }, [])

  const { mutateAsync: mutatePatchOptinTool } = useMutation({
    mutationFn: patch,
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handlePatchError
  })

  const { mutateAsync: mutateRemoveOptinTool } = useMutation({
    mutationFn: remove,
    onMutate: handleRemove,
    onSettled: handleSettled,
    onError: handleRemoveError
  })

  return {
    isLoading,
    data,
    isError,
    refetch: refetch as any,
    patch: mutatePatchOptinTool,
    remove: mutateRemoveOptinTool
  }
}
