import { type SMSCampaign } from '@ghostmonitor/recartapis'
import { hooks } from './hooks'
import moment from 'moment/moment'
import 'moment-timezone'
import { useFeatureFlag } from './use-feature-flag'

interface CanEditUntil {
  canEditUntil: moment.Moment
  hasCanEditUntilAlreadyPassed: boolean
  isLoading: boolean
}

export function useFlowCanEditUntil(smsCampaign: SMSCampaign, siteTimezone: string): CanEditUntil {
  const { data: smsTimezoneDeviation, isLoading: isSMSTimezoneDeviationLoading } =
    hooks.useSMSTimezoneDeviation()
  const { data: subscriberTimezoneOffsets, isLoading: isSubscriberTimezoneOffsetsLoading } =
    hooks.useSMSSubscriberTimezoneOffsets({ dateTime: smsCampaign?.scheduledFor ?? '' })
  const isNewSmsCampaignSchedulingEnabled = useFeatureFlag('new-sms-campaign-scheduling')
  const scheduledForInSiteTimezone = moment.tz(smsCampaign.scheduledFor, siteTimezone)
  let furthestTimezoneOffsetMinutes = scheduledForInSiteTimezone.utcOffset()

  if (subscriberTimezoneOffsets) {
    furthestTimezoneOffsetMinutes = Math.max(
      ...subscriberTimezoneOffsets
        .filter((offset) => offset.offsetMinutes !== null)
        .map((offset) => offset.offsetMinutes as number)
    )
  }

  let canEditUntil = scheduledForInSiteTimezone.clone()
  const canEditUntilInSubscriberTimezone = isNewSmsCampaignSchedulingEnabled
    ? canEditUntil.clone().subtract(furthestTimezoneOffsetMinutes, 'minutes')
    : canEditUntil.clone().subtract((smsTimezoneDeviation?.maxTz ?? 0) + 1, 'hours')

  canEditUntil =
    smsCampaign.scheduleType === 'siteTimezone' ? canEditUntil : canEditUntilInSubscriberTimezone

  return {
    canEditUntil,
    hasCanEditUntilAlreadyPassed: canEditUntil.isAfter(moment.now()),
    isLoading: isSMSTimezoneDeviationLoading || isSubscriberTimezoneOffsetsLoading
  }
}
