import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDiscountCodePools } from '../../../hooks/resources/use-discount-code-pools'
import { useSubscribersCount } from '../../../hooks/resources/use-subscribers-count'
import { flowEditorSelectors } from '../../../store/selectors'
import { flowEditorActions } from '../../../store/slices/flow-editor/flow-editor.reducer'
import { useFeatureFlag } from '../../../hooks/use-feature-flag'
import { getDiscountCodePoolUsageById } from '../utils/get-discount-code-pool-usage-by-id'

export interface UseDiscountCodePoolValidation {
  isLoading: boolean
  isPoolSizeEnough: boolean
  isAvailableCountEnough: boolean
  smallestSizePoolId: string | undefined
}

interface PoolSizes {
  target: number
  available: number
}

type PoolSizesById = Record<string, PoolSizes>

export function useDiscountCodePoolValidation(): UseDiscountCodePoolValidation {
  const { data: discountCodePools, isLoading: isDiscountCodePoolsLoading } = useDiscountCodePools()
  const dispatch = useDispatch()
  const isDiscountCodePoolModeAvailable = useFeatureFlag('introduce-discount-code-pool-mode')

  const smsCampaign = useSelector(flowEditorSelectors.selectEditorSMSCampaign)
  const { data: subscribersCount, isLoading: isSubscriberCountLoading } = useSubscribersCount(
    'sms',
    smsCampaign?.targetRules,
    smsCampaign !== undefined
  )

  const flow = useSelector(flowEditorSelectors.selectEditorFlow)
  const flowItems = useSelector(flowEditorSelectors.selectEditorFlowItems)
  const discountCodePoolUsageById = getDiscountCodePoolUsageById(
    flowItems,
    flow,
    isDiscountCodePoolModeAvailable
  )

  const attachedDiscountPoolIds = Object.keys(discountCodePoolUsageById)
  const poolSizesByIds = discountCodePools?.reduce<PoolSizesById>((acc, pool) => {
    if (attachedDiscountPoolIds.includes(pool.id)) {
      if (!acc[pool.id]) {
        acc[pool.id] = { target: 0, available: 0 }
      }

      acc[pool.id].available = pool.availableCount
      acc[pool.id].target = pool.targetCount
    }
    return acc
  }, {})

  const smallestSizePoolId = Object.entries(poolSizesByIds ?? {}).reduce<string | undefined>(
    (acc, [poolId, poolSizes]) => {
      if (acc === undefined) {
        return poolId
      }

      if (poolSizesByIds === undefined) {
        return acc
      }

      return poolSizes.target < poolSizesByIds[acc]?.target ? poolId : acc
    },
    undefined
  )

  const numberOfSubscribers = subscribersCount?.count ?? 0

  let isAvailableCountEnough = true
  let isPoolSizeEnough = true

  for (const [poolId, usageCount] of Object.entries(discountCodePoolUsageById)) {
    const poolSizes = poolSizesByIds?.[poolId]
    if (!poolSizes) {
      isAvailableCountEnough = false
      isPoolSizeEnough = false
      break
    }

    if (poolSizes.available < usageCount * numberOfSubscribers * 0.01) {
      isAvailableCountEnough = false
    }

    if (poolSizes.target < usageCount * numberOfSubscribers) {
      isPoolSizeEnough = false
    }

    if (!isAvailableCountEnough && !isPoolSizeEnough) {
      break
    }
  }

  useEffect(() => {
    if (
      isDiscountCodePoolsLoading ||
      isSubscriberCountLoading ||
      discountCodePools === undefined ||
      smsCampaign === undefined ||
      subscribersCount === undefined
    ) {
      return
    }
    dispatch(
      flowEditorActions.discountCodePoolValidated({ isPoolSizeEnough, isAvailableCountEnough })
    )
  }, [
    dispatch,
    isDiscountCodePoolsLoading,
    isSubscriberCountLoading,
    discountCodePools,
    smsCampaign,
    isPoolSizeEnough,
    isAvailableCountEnough,
    subscribersCount
  ])

  if (
    isDiscountCodePoolsLoading ||
    isSubscriberCountLoading ||
    discountCodePools === undefined ||
    smsCampaign === undefined ||
    subscribersCount === undefined
  ) {
    return {
      isLoading: true,
      isPoolSizeEnough: false,
      isAvailableCountEnough: false,
      smallestSizePoolId: undefined
    }
  }

  return {
    isLoading: isDiscountCodePoolsLoading || isSubscriberCountLoading,
    isPoolSizeEnough,
    isAvailableCountEnough,
    smallestSizePoolId
  }
}
