import data from '@emoji-mart/data'
import { Picker } from 'emoji-mart'
import { useEffect, useRef } from 'react'

// based on https://github.com/missive/emoji-mart/blob/16978d04a766eec6455e2e8bb21cd8dc0b3c7436/packages/emoji-mart/src/utils.ts#L26
export interface EmojiData {
  aliases: string[]
  emoticons: string[]
  id: string
  keywords: string[]
  name: string
  native: string
  shortcodes: string[]
  unified: string
}

// todo when https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/emoji-mart will be updated for the v5.0.0. version we should add PickerProptypes

type EmojiMartPickerProps = any

export function EmojiMartPicker(props: EmojiMartPickerProps) {
  const ref = useRef() as React.MutableRefObject<any>

  useEffect(() => {
    // eslint-disable-next-line no-new
    new Picker({ ...props, data, ref })
  }, [])

  return <div ref={ref} />
}
