import { type SMSCampaign } from '@ghostmonitor/recartapis'
import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { getSMSCampaignUIStatus } from '../../utils/sms/get-sms-campaign-ui-status'
import { QUERY_KEY } from './query-keys'
import { api } from '../../utils/api'

export interface UseSmsCampaign {
  data: SMSCampaign | undefined
  isLoading: boolean
}

export function useSMSCampaign(
  smsCampaignId: string,
  options?: UseQueryOptions<SMSCampaign>
): UseSmsCampaign {
  const queryKey = [QUERY_KEY.smsCampaign, smsCampaignId]

  const { isInitialLoading, data: smsCampaign } = useQuery({
    ...options,
    queryKey,
    queryFn: async () => {
      return api.getSMSCampaign(smsCampaignId)
    },
    select: (smsCampaign: SMSCampaign | undefined) => {
      if (!smsCampaign) {
        return
      }

      return {
        ...smsCampaign,
        status: getSMSCampaignUIStatus(smsCampaign)
      }
    }
  })

  return {
    data: smsCampaign,
    isLoading: isInitialLoading
  }
}
