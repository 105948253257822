import {
  type GetStaticDiscountCodeResponse,
  type StaticDiscountCode
} from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { useQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { api } from '../../utils/api'
import { serializedError } from '../../utils/serialized-error'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export enum DiscountCodeError {
  DiscountCodeInvalid = 'DiscountCodeInvalid',
  DiscountCodeNotExists = 'DiscountCodeNotExists',
  DiscountCodeExpired = 'DiscountCodeExpired'
}

export function useDiscountCodeDebounced(
  discountCode: string | undefined,
  debounceTime = 500
): UseResource<StaticDiscountCode> {
  const [debouncedDiscountCode, setDebouncedDiscountCode] = useState(discountCode)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedDiscountCode(discountCode)
    }, debounceTime)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [discountCode, debounceTime])

  const queryKey = [QUERY_KEY.discountCodes, debouncedDiscountCode]
  let {
    isInitialLoading,
    isError,
    data,
    error: requestError
  } = useQuery<GetStaticDiscountCodeResponse, AxiosError<GetStaticDiscountCodeResponse>>({
    queryKey,
    queryFn: async () => api.getDiscountCode(debouncedDiscountCode),
    enabled: debouncedDiscountCode !== undefined && debouncedDiscountCode !== '',
    staleTime: 10000
  })

  let error: SerializedError = requestError
  if (!isInitialLoading && data?.data?.expired) {
    isError = true
    error = serializedError(DiscountCodeError.DiscountCodeExpired)
  }

  if (!isInitialLoading && requestError?.response?.status === 404) {
    error = serializedError(DiscountCodeError.DiscountCodeNotExists)
  }
  if (!isInitialLoading && requestError && requestError?.response?.status >= 500) {
    error = serializedError(DiscountCodeError.DiscountCodeInvalid)
  }

  return {
    data: data?.data,
    isLoading: isInitialLoading,
    isError,
    error
  }
}
