import {
  type AppAuth,
  type BoldSubscriptionAppAuth,
  type KlaviyoAppAuth,
  type LoyaltyLionAppAuth,
  type NetcoreCloudAppAuth,
  type YotpoAppAuth,
  type YotpoReviewAppAuth
} from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'

export enum IntegrationSlug {
  KLAVIYO = 'klaviyo',
  DRIP = 'drip',
  POSTSCRIPT = 'postscript',
  OMNISEND = 'omnisend',
  ATTENTIVE = 'attentive',
  GORGIAS = 'gorgias',
  LOOP = 'loop',
  MINDBOX = 'mindbox',
  OPTIMONK = 'optimonk',
  TYPEFORM = 'typeform',
  WHEELIO = 'wheelio',
  WONDERMENT = 'wonderment',
  ACTIVE_CAMPAIGN = 'active_campaign',
  RECHARGE = 'recharge',
  SENDLANE = 'sendlane',
  YOTPO = 'yotpo',
  YOTPO_REVIEW = 'yotpo_review',
  LOYALTY_LION = 'loyalty_lion',
  BOLD = 'bold',
  NETCORE_CLOUD = 'netcore_cloud'
}

export interface AppIntegration {
  apiKey: string
  connectedAt: string
  integrationName: IntegrationSlug
  isEnabled: boolean
  siteId: string
  subscribedEvents: string[]
  integrationConfig?: unknown
}

export type UpdateIntegrationPayload = Pick<
  Partial<AppAuth>,
  'apiKey' | 'integrationConfig' | 'isEnabled'
>

export interface Klaviyo extends AppIntegration {
  integrationConfig: {
    klaviyoSiteId: string
    defaultListId?: string
    lists?: KlaviyoAppAuth['integrationConfig']['lists']
  }
}

export interface Drip extends AppIntegration {
  integrationConfig: {
    accountId: string
  }
}

export interface Sendlane extends AppIntegration {
  integrationConfig: {
    defaultListId: string
  }
}

export interface Postscript extends AppIntegration {
  integrationConfig: {
    defaultListId: string
  }
}

export interface Omnisend extends AppIntegration {
  integrationConfig: {
    accountId: string
  }
}

export interface Yotpo extends AppIntegration {
  integrationConfig: YotpoAppAuth['integrationConfig']
}

export interface YotpoReview extends AppIntegration {
  integrationConfig: YotpoReviewAppAuth['integrationConfig']
}

export interface LoyaltyLion extends AppIntegration {
  integrationConfig: LoyaltyLionAppAuth['integrationConfig']
}

export interface BoldSubscriptions extends AppIntegration {
  integrationConfig: BoldSubscriptionAppAuth['integrationConfig']
}

export interface NetcoreCloud extends AppIntegration {
  integrationConfig: NetcoreCloudAppAuth['integrationConfig']
}

export interface RequestMeta {
  isLoading: boolean
  error: SerializedError | null
}

export const requestInitialMeta: RequestMeta = {
  isLoading: false,
  error: null
}

export interface KlaviyoListItem {
  list_id: string

  list_name: string
  opt_in_process: 'single_opt_in' | 'double_opt_in'
}

export interface PostScriptListItem {
  id: string
  name: string
}

export interface SendlaneListItem {
  id: string
  name: string
  description: string
  status: string
  created_at: string
}

export interface ActiveCampaign extends AppIntegration {
  integrationConfig: {
    baseURL: string
  }
}
