import type {
  AISettingsTextGenerationPromptPersona,
  Blast,
  ButtonUI,
  CustomerChatSettings,
  Device,
  EditorField,
  EmbeddedForm,
  EmbeddedFormGeotargetingError,
  EmbeddedFormSettings,
  EmbeddedFormWidthSettings,
  FacebookPage,
  FlowItemUI,
  GeneralStyle,
  InHouseABTesting,
  KeywordSettings,
  LandingPage,
  LandingPageBackgroundSettings,
  LandingPageDesktopAdditionalImageSettings,
  LandingPageDisplayRules,
  LandingPageGeotargetingError,
  LandingPageMarginSettings,
  LandingPageSettings,
  LinkingMeta,
  LogicRandomSplitVariant,
  MessengerGenericTemplatePayload,
  MessengerMessageUI,
  NetworkOptin,
  OptinForm,
  OptinTool,
  OptinToolShadow,
  OptinToolStep,
  Popup,
  PopupAdditionalImageSettings,
  PopupBackgroundSettings,
  PopupBoxContainerField,
  PopupCloseIconSettings,
  PopupDisplayRules,
  PopupLinkButtonField,
  PopupLinkTextField,
  PopupMinimizedSettings,
  PopupSettings,
  PopupSuccessSettings,
  PopupTeaserSettings,
  QuickReply,
  ResendButtonField,
  SMSMessageFlowItemUI,
  SMSMessageTextUI,
  SMSMessageUI,
  SequenceItemUI,
  SequenceUI,
  SiteEngineSlug,
  SiteShopify,
  ThirdPartyABTesting,
  URLGenerationSettings,
  User
} from '@ghostmonitor/recartapis'
import { createSelector, type Selector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { type SequenceEditorSettings } from '../routes/SequenceEditor/settings/context/sequence-editor-settings.type'
import { type TrayItem } from '../routes/SequenceEditor/types/tray-item.type'
import { type AppIntegration } from '../routes/integrations/integrations.type'
import { type OptinToolMenuItem } from '../routes/optin-tools/components/editor/left-panel/left-panel.component'
import { type FbMessengerConfig } from '../types/facebook/facebook-messenger-config.type'
import { type DashboardState } from './dashboard.state'
import * as app from './slices/app/app.selectors'
import * as facebook from './slices/facebook/facebook.selectors'
import * as flowEditor from './slices/flow-editor/flow-editor.selectors'
import { type IntegrationMeta } from './slices/integrations/integrations.reducer'
import * as integrations from './slices/integrations/integrations.selectors'
import * as me from './slices/me/me.selectors'
import { type SiteState, type UserRole } from './slices/me/me.types'
import * as modal from './slices/modal/modal.selectors'
import { type ModalType } from './slices/modal/modal.type'
import type {
  AccordionError,
  InputState,
  MenuItemError,
  OptinToolEditorState,
  OptinToolMeta,
  OptinToolMetaErrorField
} from './slices/optin-tool-editor/optin-tool-editor.reducer'
import * as optinTool from './slices/optin-tool-editor/optin-tool-editor.selectors'
import * as sequenceEditor from './slices/sequence-editor/sequence-editor.selectors'
import type {
  BlastMeta,
  ButtonMeta,
  MessageItemMeta,
  MessageMeta,
  QuickReplyMeta,
  SequenceEditorState,
  SequenceItemMeta,
  SequenceMeta,
  SplitMeta,
  VariableMeta
} from './slices/sequence-editor/sequence-editor.state'
import * as site from './slices/site/site.selectors'

export const selectLaunchDarklyFlags = (state: DashboardState): { [flagName: string]: any } =>
  app.selectLaunchDarklyFlags(state.app)
export const selectLaunchDarklyFlag = memoize((flagName: string) =>
  createSelector(selectLaunchDarklyFlags, (launchDarklyFlags) => launchDarklyFlags?.[flagName])
)
export const selectSidebarOpen = (state: DashboardState): boolean =>
  app.selectSidebarOpen(state.app)
export const selectIsAdblockEnabled = (state: DashboardState): boolean =>
  app.selectIsAdblockEnabled(state.app)

export const selectFacebookPageConfig = (state: DashboardState): FbMessengerConfig =>
  facebook.selectFacebookPageConfig(state.facebook)
export const selectFacebookPageConfigMeta = (
  state: DashboardState
): { fbConnectionError: string } => facebook.selectFacebookPageConfigMeta(state.facebook)

export const selectFacebookPageId = (state: DashboardState): string =>
  facebook.selectFacebookPageId(state.facebook)
export const selectFacebookPageUserId = (state: DashboardState): string =>
  facebook.selectFacebookPageUserId(state.facebook)

export const selectIsFacebookPageConnected = (state: DashboardState): boolean =>
  facebook.selectIsFacebookPageConnected(state.facebook)

export const selectFacebookPages = (state: DashboardState): FacebookPage.Page[] =>
  facebook.selectFacebookPages(state.facebook)
export const selectFacebookPagesMeta = (state: DashboardState): any =>
  facebook.selectFacebookPagesMeta(state.facebook)

export const selectFacebookUserId = (state: DashboardState): string =>
  facebook.selectFacebookUserId(state.facebook)

export const selectFacebookAuthorizationToken = (state: DashboardState): string =>
  facebook.selectAuthorizationToken(state.facebook)

/** INTEGRATIONS **/
export const selectIntegrationsBySlug = (
  state: DashboardState
): { [integrationName: string]: AppIntegration } =>
  integrations.selectIntegrationsBySlug(state.integrations)
export const selectIntegrationsMetaBySlug = (
  state: DashboardState
): { [integrationName: string]: IntegrationMeta } =>
  integrations.selectIntegrationsMetaBySlug(state.integrations)

export const selectIntegration =
  <T extends AppIntegration>(integrationName: string): Selector<DashboardState, T> =>
  (state: DashboardState) =>
    integrations.selectIntegration<T>(integrationName)(state.integrations)

export const selectIntegrationMeta =
  (integrationName: string) =>
  (state: DashboardState): IntegrationMeta =>
    integrations.selectIntegrationMeta(integrationName)(state.integrations)

export const selectSite = (state: DashboardState): SiteState => me.selectSite(state.me)
export const selectSiteId = (state: DashboardState): string => me.selectSiteId(state.me)
export const selectUrlGenerationSettings = (state: DashboardState): URLGenerationSettings =>
  me.selectUrlGenerationSettings(state.me)
export const selectSiteUrl = (state: DashboardState): string => me.selectSiteUrl(state.me)
export const selectSiteName = (state: DashboardState): string => me.selectSiteName(state.me)
export const selectSupportEmail = (state: DashboardState): string => me.selectSupportEmail(state.me)
export const selectShopify = (state: DashboardState): SiteShopify => me.selectShopify(state.me)
export const selectAISettingsPersonas = (
  state: DashboardState
): AISettingsTextGenerationPromptPersona[] => me.selectAISettingsPersonas(state.me)
export const selectCurrencySlug = (state: DashboardState): string => me.selectCurrencySlug(state.me)
export const selectUser = (state: DashboardState): User => me.selectUser(state.me)
export const selectUserRole = (state: DashboardState): UserRole => me.selectUserRole(state.me)

/** OPTIN TOOL EDITOR **/
export const selectOptinTool = <T extends OptinTool>(state: DashboardState): T =>
  optinTool.selectOptinTool(state.optinToolEditor as OptinToolEditorState<T>)

export const selectResolvedPopup = (state: DashboardState): Popup =>
  optinTool.selectResolvedPopup(state.optinToolEditor as OptinToolEditorState<Popup>)

export const selectOptinToolSteps = <T extends Popup | LandingPage | EmbeddedForm>(
  state: DashboardState
): OptinToolStep[] =>
  optinTool.selectOptinToolSteps(state.optinToolEditor as OptinToolEditorState<T>)

export const selectIsOneClickOptinTool = <T extends Popup | LandingPage | EmbeddedForm>(
  state: DashboardState
): boolean => optinTool.selectIsOneClickOptinTool(state.optinToolEditor as OptinToolEditorState<T>)

export const selectOptinToolSettings = <
  T extends
    | PopupSettings
    | CustomerChatSettings
    | KeywordSettings
    | LandingPageSettings
    | EmbeddedFormSettings
>(
  state: DashboardState
): T =>
  optinTool.selectOptinToolSettings(state.optinToolEditor as OptinToolEditorState<OptinTool>) as T

export const selectOptinToolSettingsField =
  <
    T extends
      | PopupBackgroundSettings
      | PopupAdditionalImageSettings
      | PopupCloseIconSettings
      | PopupTeaserSettings
      | PopupSuccessSettings
      | PopupMinimizedSettings
      | PopupDisplayRules
      | PopupBoxContainerField
      | EditorField
      | LandingPageBackgroundSettings
      | LandingPageDesktopAdditionalImageSettings
      | LandingPageDisplayRules
      | LandingPageGeotargetingError
      | LandingPageMarginSettings
      | EmbeddedFormGeotargetingError
      | EmbeddedFormWidthSettings
      | ThirdPartyABTesting
      | InHouseABTesting
      | OptinForm
      | ResendButtonField
      | PopupLinkTextField
      | PopupLinkButtonField
      | OptinToolShadow
      | NetworkOptin
      | string
      | undefined
  >(
    fieldName: string
  ) =>
  (state: DashboardState) =>
    optinTool.selectOptinToolSettingsField<T>(state.optinToolEditor, fieldName)

export const selectOptinToolSettingsCopiedField =
  <
    T extends
      | PopupBackgroundSettings
      | PopupAdditionalImageSettings
      | PopupCloseIconSettings
      | PopupMinimizedSettings
      | PopupDisplayRules
      | EditorField
      | LandingPageBackgroundSettings
      | LandingPageDesktopAdditionalImageSettings
      | LandingPageDisplayRules
      | string
  >(
    copiedFieldName: string
  ) =>
  (state: DashboardState) =>
    optinTool.selectOptinToolSettingsCopiedField<T>(state.optinToolEditor, copiedFieldName)

export const selectOptinToolDevices = (state: DashboardState): string[] =>
  optinTool.selectOptinToolDevices(state.optinToolEditor)

export const selectOptinToolSequenceId = (state: DashboardState): string | null =>
  optinTool.selectOptinToolSequenceId(state.optinToolEditor)

export const selectOptinToolMeta = (state: DashboardState): OptinToolMeta =>
  optinTool.selectOptinToolMeta(state.optinToolEditor)

export const selectOptinToolActiveMenuItem = (state: DashboardState): OptinToolMenuItem | null =>
  optinTool.selectOptinToolActiveMenuItem(state.optinToolEditor)

export const selectOptinToolActivePreviewDevice = (state: DashboardState): Device =>
  optinTool.selectOptinToolActivePreviewDevice(state.optinToolEditor)

export const selectOptinToolInputState = (state: DashboardState): InputState =>
  optinTool.selectOptinToolInputState(state.optinToolEditor)

export const selectOptinToolMenuItems = (state: DashboardState): OptinToolMenuItem[] =>
  optinTool.selectOptinToolMenuItems(state.optinToolEditor)

export const selectOptinToolErrorByField =
  (view: string, accordionSlug: string, fieldName: string) =>
  (state: DashboardState): OptinToolMetaErrorField | undefined =>
    optinTool.selectOptinToolErrorByField(state.optinToolEditor, view, accordionSlug, fieldName)

export function selectOptinToolErrorMeta(menuSlug: string, accordionSlug: string, fieldName: string)
export function selectOptinToolErrorMeta(menuSlug: string, accordionSlug: string)
export function selectOptinToolErrorMeta(menuSlug: string)
export function selectOptinToolErrorMeta(
  menuSlug: string,
  accordionSlug?: string,
  fieldName?: string
) {
  return (
    state: DashboardState
  ): OptinToolMetaErrorField | OptinToolMetaErrorField[] | AccordionError | MenuItemError => {
    if (accordionSlug !== undefined && fieldName !== undefined) {
      return optinTool.selectOptinToolErrorMeta(
        state.optinToolEditor,
        menuSlug,
        accordionSlug,
        fieldName
      )
    } else if (accordionSlug !== undefined) {
      return optinTool.selectOptinToolErrorMeta(state.optinToolEditor, menuSlug, accordionSlug)
    } else {
      return optinTool.selectOptinToolErrorMeta(state.optinToolEditor, menuSlug)
    }
  }
}

export const selectHasOptinToolErrorOnAccordion =
  (menuSlug: string, accordionSlug: string) =>
  (state: DashboardState): boolean =>
    optinTool.selectHasOptinToolErrorOnAccordion(state.optinToolEditor, menuSlug, accordionSlug)

export const selectIsPreviewInFullscreen = (state: DashboardState) =>
  optinTool.selectIsPreviewInFullscreen(state.optinToolEditor)

export const selectRecentlyUsedFontTypes = (state: DashboardState) =>
  optinTool.selectRecentlyUsedFontTypes(state.optinToolEditor)

export const selectPopupGeneralStyle =
  <T extends GeneralStyle>(slug: string | null) =>
  (state: DashboardState): T | null =>
    optinTool.selectPopupGeneralStyle(state.optinToolEditor as OptinToolEditorState<Popup>, slug)

/** FlowEditor **/

export const flowEditorSelectors = {
  selectNodes: (state: DashboardState) => flowEditor.selectNodes(state.flowEditor),
  selectNodeById:
    (nodeId: string, allowUndefined = false) =>
    (state: DashboardState) =>
      flowEditor.selectNodeById(nodeId, allowUndefined)(state.flowEditor),
  selectNodeByFlowItemId: (flowItemId: string) => (state: DashboardState) =>
    flowEditor.selectNodeByFlowItemId(flowItemId)(state.flowEditor),
  selectEditorSMSCampaign: (state: DashboardState) =>
    flowEditor.selectEditorSMSCampaign(state.flowEditor),
  selectEditorSMSCampaignMeta: (state: DashboardState) =>
    flowEditor.selectEditorSMSCampaignMeta(state.flowEditor),
  selectEditorFlow: (state: DashboardState) => flowEditor.selectEditorFlow(state.flowEditor),
  selectEditorFlowItems: (state: DashboardState) =>
    flowEditor.selectEditorFlowItems(state.flowEditor),
  selectEditorFlowMeta: (state: DashboardState) =>
    flowEditor.selectEditorFlowMeta(state.flowEditor),
  selectEditorSiteId: (state: DashboardState) => flowEditor.selectSiteId(state.flowEditor),
  selectFlowItemMeta: (flowItemId: string) => (state: DashboardState) =>
    flowEditor.selectFlowItemMeta(flowItemId)(state.flowEditor),
  selectEdges: (state: DashboardState) => flowEditor.selectEdges(state.flowEditor),
  selectEdgeById: (edgeId: string) => (state: DashboardState) =>
    flowEditor.selectEdgeById(edgeId)(state.flowEditor),
  selectInvalidDiscountCodes: (state: DashboardState) =>
    flowEditor.selectInvalidDiscountCodes(state.flowEditor),
  selectExpiredDiscountCodes: (state: DashboardState) =>
    flowEditor.selectExpiredDiscountCodes(state.flowEditor),
  selectVariableTypeOptions: (state: DashboardState) =>
    flowEditor.selectVariableTypeOptions(state.flowEditor),
  selectEntryVariableTypeOptions: (state: DashboardState) =>
    flowEditor.selectEntryVariableTypeOptions(state.flowEditor),
  selectHasShortCode: (state: DashboardState) => flowEditor.hasShortCodeNumber(state.flowEditor),
  selectLinkTypeOptions: (state: DashboardState) =>
    flowEditor.selectLinkTypeOptions(state.flowEditor),
  selectEntryLinkTypeOptions: (state: DashboardState) =>
    flowEditor.selectEntryLinkTypeOptions(state.flowEditor),
  selectFlowItem:
    <TFlowItem extends FlowItemUI = SMSMessageFlowItemUI>(flowItemId: string) =>
    (state: DashboardState) =>
      flowEditor.selectFlowItem<TFlowItem>(flowItemId)(state.flowEditor),
  selectFlowItemErrors: (flowItemId: string) => (state: DashboardState) =>
    flowEditor.selectFlowItemErrors(flowItemId)(state.flowEditor),
  selectFlowItemIsInQuietHours: (flowItemId: string) => (state: DashboardState) =>
    flowEditor.selectFlowItemIsInQuietHours(flowItemId)(state.flowEditor),
  selectFlowItems: (state: DashboardState) => flowEditor.selectFlowItems(state.flowEditor),
  selectFlowItemsById: (state: DashboardState) => flowEditor.selectFlowItemsById(state.flowEditor),
  selectMessage:
    <TMessage extends SMSMessageUI = SMSMessageUI>(flowItemId: string, messageIndex: number) =>
    (state: DashboardState) =>
      flowEditor.selectMessage<TMessage>(flowItemId, messageIndex)(state.flowEditor),
  selectMessageMeta: (flowItemId: string, messageIndex: number) => (state: DashboardState) =>
    flowEditor.selectMessageMeta(flowItemId, messageIndex)(state.flowEditor),
  selectVariablePlaceholder: (state: DashboardState) =>
    flowEditor.selectVariablePlaceholder(state.flowEditor),
  selectIsReadOnly: (state: DashboardState): boolean =>
    flowEditor.selectIsReadOnly(state.flowEditor),
  selectIsEverSaved: (state: DashboardState): boolean =>
    flowEditor.selectIsEverSaved(state.flowEditor),
  selectIsScheduledDelayEnabled: (state: DashboardState): boolean =>
    flowEditor.selectIsScheduledDelayEnabled(state.flowEditor),
  selectErrorCount: (state: DashboardState): number =>
    flowEditor.selectErrorCount(state.flowEditor),
  selectErrors: (state: DashboardState) => flowEditor.selectErrors(state.flowEditor),
  selectQuietHoursConfig: (state: DashboardState) =>
    flowEditor.selectQuietHoursConfig(state.flowEditor)
}

/** SEQUENCE EDITOR **/
export const selectSequenceEditor = (state: DashboardState): SequenceEditorState =>
  sequenceEditor.selectSequenceEditor(state.sequenceEditor)
export const selectInitializedSettings = (state: DashboardState): SequenceEditorSettings[] =>
  sequenceEditor.selectInitializedSettings(state.sequenceEditor)
export const selectIsSettingsInitialized = (state: DashboardState): boolean =>
  sequenceEditor.selectIsSettingsInitialized(state.sequenceEditor)
export const selectEditorSequence = (state: DashboardState): SequenceUI =>
  sequenceEditor.selectEditorSequence(state.sequenceEditor)
export const selectEditorBlast = (state: DashboardState): Blast =>
  sequenceEditor.selectEditorBlast(state.sequenceEditor)
export const selectEditorBlastMeta = (state: DashboardState): BlastMeta =>
  sequenceEditor.selectEditorBlastMeta(state.sequenceEditor)
export const selectEditorSequenceMeta = (state: DashboardState): SequenceMeta =>
  sequenceEditor.selectEditorSequenceMeta(state.sequenceEditor)

export const selectSequenceItemsById = (
  state: DashboardState
): {
  [sequenceItemId: string]: SequenceItemUI
} => sequenceEditor.selectSequenceItemsById(state.sequenceEditor)
export const selectSequenceItemsMetaById = (
  state: DashboardState
): {
  [sequenceItemId: string]: SequenceItemMeta
} => sequenceEditor.selectSequenceItemsMetaById(state.sequenceEditor)

export const selectDraggedItemType = (state: DashboardState): TrayItem =>
  sequenceEditor.selectDraggedItemType(state.sequenceEditor)
export const selectShowSequenceItemStatistics = (state: DashboardState): boolean =>
  sequenceEditor.selectShowSequenceItemStatistics(state.sequenceEditor)
export const selectShowDebugView = (state: DashboardState): boolean =>
  sequenceEditor.selectShowDebugView(state.sequenceEditor)
export const selectLinking = (state: DashboardState): boolean =>
  sequenceEditor.selectLinking(state.sequenceEditor)
export const selectLinkingMeta = (state: DashboardState): LinkingMeta =>
  sequenceEditor.selectLinkingMeta(state.sequenceEditor)

export const selectEntrySequenceItem = (state: DashboardState): SequenceItemUI =>
  sequenceEditor.selectEntrySequenceItem(state.sequenceEditor)

export const selectValidDiscountCodes = (state: DashboardState): string[] =>
  sequenceEditor.selectValidDiscountCodes(state.sequenceEditor)

export const selectInvalidDiscountCodes = (state: DashboardState): string[] =>
  sequenceEditor.selectInvalidDiscountCodes(state.sequenceEditor)

export const selectExpiredDiscountCodes = (state: DashboardState): string[] =>
  sequenceEditor.selectExpiredDiscountCodes(state.sequenceEditor)

export const selectIsEntrySequenceItem =
  (sequenceItemId: string): ((state: DashboardState) => boolean) =>
  (state: DashboardState) =>
    sequenceEditor.selectIsEntrySequenceItem(sequenceItemId)(state.sequenceEditor)

export const selectSequenceItem =
  <T extends SequenceItemUI = SequenceItemUI>(
    sequenceItemId: string
  ): Selector<DashboardState, T> =>
  (state: DashboardState) =>
    sequenceEditor.selectSequenceItem<T>(sequenceItemId)(state.sequenceEditor)

export const selectSequenceItemMeta =
  (sequenceItemId: string): ((state: DashboardState) => SequenceItemMeta) =>
  (state: DashboardState) =>
    sequenceEditor.selectSequenceItemMeta(sequenceItemId)(state.sequenceEditor)

export const selectMessage =
  <TMessage extends MessengerMessageUI | SMSMessageTextUI = MessengerMessageUI>(
    sequenceItemId: string,
    messageIndex: number
  ): Selector<DashboardState, TMessage> =>
  (state: DashboardState) =>
    sequenceEditor.selectMessage<TMessage>(sequenceItemId, messageIndex)(state.sequenceEditor)

export const selectMessageMeta =
  (sequenceItemId: string, messageIndex: number): ((state: DashboardState) => MessageMeta) =>
  (state: DashboardState) =>
    sequenceEditor.selectMessageMeta(sequenceItemId, messageIndex)(state.sequenceEditor)

export const selectVariableMeta =
  (sequenceItemId: string, messageIndex: number, variableIndex: number) =>
  (state: DashboardState): VariableMeta =>
    sequenceEditor.selectVariableMeta(
      sequenceItemId,
      messageIndex,
      variableIndex
    )(state.sequenceEditor)

export const selectMessageItem =
  (sequenceItemId: string, messageIndex: number, messageItemIndex: number) =>
  (state: DashboardState): MessengerGenericTemplatePayload | undefined =>
    sequenceEditor.selectMessageItem(
      sequenceItemId,
      messageIndex,
      messageItemIndex
    )(state.sequenceEditor)

export const selectMessageItemMeta =
  (sequenceItemId: string, messageIndex: number, messageItemIndex: number) =>
  (state: DashboardState): MessageItemMeta =>
    sequenceEditor.selectMessageItemMeta(
      sequenceItemId,
      messageIndex,
      messageItemIndex
    )(state.sequenceEditor)

export const selectButton =
  <T extends ButtonUI>(
    sequenceItemId: string,
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex?: number
  ) =>
  (state: DashboardState): T =>
    sequenceEditor.selectButton<T>(
      sequenceItemId,
      messageIndex,
      buttonIndex,
      messageItemIndex
    )(state.sequenceEditor)

export const selectButtonMeta =
  (sequenceItemId: string, messageIndex: number, buttonIndex: number, messageItemIndex?: number) =>
  (state: DashboardState): ButtonMeta =>
    sequenceEditor.selectButtonMeta(
      sequenceItemId,
      messageIndex,
      buttonIndex,
      messageItemIndex
    )(state.sequenceEditor)

export const selectQuickReplies =
  (sequenceItemId: string) =>
  (state: DashboardState): QuickReply[] =>
    sequenceEditor.selectQuickReplies(sequenceItemId)(state.sequenceEditor)

export const selectQuickReply =
  (sequenceItemId: string, quickReplyIndex: number): ((state: DashboardState) => QuickReply) =>
  (state: DashboardState) =>
    sequenceEditor.selectQuickReply(sequenceItemId, quickReplyIndex)(state.sequenceEditor)

export const selectQuickReplyMeta =
  (sequenceItemId: string, quickReplyIndex: number) =>
  (state: DashboardState): QuickReplyMeta =>
    sequenceEditor.selectQuickReplyMeta(sequenceItemId, quickReplyIndex)(state.sequenceEditor)

export const selectSplit =
  (sequenceItemId: string, splitIndex: number) =>
  (state: DashboardState): LogicRandomSplitVariant =>
    sequenceEditor.selectSplit(sequenceItemId, splitIndex)(state.sequenceEditor)

export const selectSplitMeta =
  (sequenceItemId: string, splitIndex: number) =>
  (state: DashboardState): SplitMeta =>
    sequenceEditor.selectSplitMeta(sequenceItemId, splitIndex)(state.sequenceEditor)

export const selectErrorCount = (state: DashboardState): number =>
  sequenceEditor.selectErrorCount(state.sequenceEditor)

export const selectIsReadOnly = (state: DashboardState): boolean =>
  sequenceEditor.selectIsReadOnly(state.sequenceEditor)

export const selectSequenceItemErrorCount =
  (sequenceItemId: string) =>
  (state: DashboardState): number =>
    sequenceEditor.selectSequenceItemErrorCount(sequenceItemId)(state.sequenceEditor)

export const selectSaving = (state: DashboardState): boolean =>
  sequenceEditor.selectSaving(state.sequenceEditor)
export const selectSaved = (state: DashboardState): boolean =>
  sequenceEditor.selectSaved(state.sequenceEditor)
export const selectUnsavedChanges = (state: DashboardState): boolean =>
  sequenceEditor.selectUnsavedChanges(state.sequenceEditor)

export const selectIsModalOpen =
  (modalType: ModalType) =>
  (state: DashboardState): boolean =>
    modal.selectIsModalOpen(modalType)(state.modal)

export const selectLocationOnModalDone = (state: DashboardState): string | undefined =>
  modal.selectLocationOnDone(state.modal)

export const selectIsSettingsDrawerVisible = (state: DashboardState): boolean =>
  sequenceEditor.selectIsSettingsDrawerVisible(state.sequenceEditor)

export const selectSiteEngineSlug = (state: DashboardState): SiteEngineSlug | undefined =>
  site.selectSiteEngineSlug(state.me.site)

export const selectSiteTimezone = (state: DashboardState): string | undefined =>
  site.selectTimeZone(state.me.site)
