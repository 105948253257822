import type {
  GeneralStyle,
  PopupBoxContainerField,
  PopupButtonField,
  PopupImageField,
  PopupInputField,
  PopupLinkTextField,
  PopupOptionalInputField,
  PopupStaticTextField,
  PopupTextAreaField,
  PopupTextField
} from '@ghostmonitor/recartapis'
import flatMapDeep from 'lodash/flatMapDeep'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import {
  accordionLabels,
  previewLabels
} from '../../../../popup/editor/preview/components/utils/preview-labels'

export type GeneralStyleEditorField =
  | PopupImageField
  | PopupButtonField
  | PopupInputField
  | PopupOptionalInputField
  | PopupTextField
  | PopupTextAreaField
  | PopupStaticTextField
  | PopupBoxContainerField
  | PopupLinkTextField

export function convertPathToLabel(path: string) {
  const [field, key] = path.split('.')

  // email success is on the sms step visually
  if (path === 'email.success') {
    return `${previewLabels.sms} / ${accordionLabels[key]}`
  }

  // success uses description property which is normally translated to legal instead of subheading
  if (path === 'success.description') {
    return `${previewLabels.success} / ${accordionLabels.paragraph}`
  }

  if (path === 'success.networkOptin.description') {
    return `${previewLabels.success} / ${accordionLabels.description}`
  }

  if (path === 'smsWithEmail.email.input') {
    return `${previewLabels.smsWithEmail} / ${accordionLabels.emailInput}`
  }
  if (path === 'smsWithEmail.input') {
    return `${previewLabels.smsWithEmail} / ${accordionLabels.phoneInput}`
  }

  return `${previewLabels[field]} / ${accordionLabels[key]}`
}

export function getOverwrittenProperties(field: GeneralStyleEditorField) {
  const ignoreKeys = ['type', 'generalStyleSlug']

  if (field.type === 'button') {
    ignoreKeys.push('text')
  }

  if (
    field.type === 'text' ||
    field.type === 'textarea' ||
    field.type === 'static-text' ||
    field.type === 'link-text'
  ) {
    ignoreKeys.push(...['text', 'font.size'])
  }

  if (field.type === 'input' || field.type === 'optional-input') {
    ignoreKeys.push(...['defaultState.placeholderText', 'errorState.text', 'required'])
  }

  // remove keys that are not defined in general style
  return omit(field, ignoreKeys)
}

export function getGeneralStyleMergableProperties(
  generalStyle: GeneralStyle,
  field: GeneralStyleEditorField
): GeneralStyle {
  if (field.type === 'image' || field.type === 'box-container') {
    return generalStyle
  }

  const ignoreKeys: string[] = []
  if (generalStyle.type === 'button' || generalStyle.type === 'text') {
    const shouldRemoveCustomFontUrlFromGeneralStyle =
      generalStyle.font.type === 'custom' && 'font' in field && field.font?.type !== 'custom'

    if (shouldRemoveCustomFontUrlFromGeneralStyle) {
      ignoreKeys.push('font.url')
    }
  }

  if (generalStyle.type === 'input' && field.type === 'input') {
    const shouldRemoveCustomFontUrlFromGeneralStyle =
      generalStyle.defaultState.font.type === 'custom' && field.defaultState.font?.type !== 'custom'
    if (shouldRemoveCustomFontUrlFromGeneralStyle) {
      ignoreKeys.push('defaultState.font.url')
    }
  }

  return omit(generalStyle, ignoreKeys) as GeneralStyle
}

export function hasOverWrittenStyle(field: GeneralStyleEditorField) {
  const settings = getOverwrittenProperties(field)
  // remove empty nested objects that are left over after omitting
  const cleaned = flatMapDeep(settings).filter((x) => !isEmpty(x))

  return Object.keys(cleaned).length > 0
}

export function convertFieldSettingsToGeneralStyle(
  field: GeneralStyleEditorField,
  generalStyle: GeneralStyle
): GeneralStyle {
  return merge(
    {},
    getGeneralStyleMergableProperties(generalStyle, field),
    getOverwrittenProperties(field)
  )
}
