import {
  type FlowItemUI,
  type FlowUI,
  getConnectedFlowItemApiIds,
  isSMSMessageFlowItemAPI
} from '@ghostmonitor/recartapis'
import { convertFlowItemUIToFlowItem } from '../../../types/flow-editor/convert-flow-item-ui-to-flow-item'
import { convertFlowUIToFlow } from '../../../types/flow-editor/convert-flow-ui-to-flow'

export function getDiscountCodePoolUsageById(
  flowItems: FlowItemUI[] | undefined,
  flow: FlowUI | undefined,
  shouldCountMultipleUsage = false
): Record<string, number> {
  if (!flowItems || !flow) {
    return {}
  }

  const flowItemAPIs = flowItems?.map((item) => convertFlowItemUIToFlowItem(item, flow))
  const flowAPI = convertFlowUIToFlow(flow)

  const discountCodePoolUsageById: Record<string, number> = {}
  // Sufficient to keep track "globally" because we don't want to traverse all path only all items once
  const processedItemIds = new Set<string>()

  const processNextItems = (flowItemId: string) => {
    if (processedItemIds.has(flowItemId)) {
      return
    }

    const flowItem = flowItemAPIs.find((item) => item._id === flowItemId)

    if (!flowItem) {
      return
    }

    processedItemIds.add(flowItemId)

    if (isSMSMessageFlowItemAPI(flowItem)) {
      const discountCodePoolId = flowItem.item.discountCodePoolId
      if (discountCodePoolId) {
        discountCodePoolUsageById[discountCodePoolId] =
          (discountCodePoolUsageById[discountCodePoolId] ?? 0) + 1
      }
    }

    const connectedFlowItemIds = getConnectedFlowItemApiIds(flowItem)
    for (const connectedFlowItemId of connectedFlowItemIds) {
      processNextItems(connectedFlowItemId)
    }
  }

  const entryFlowItemId = flowAPI.entrySequenceItemId
  processNextItems(entryFlowItemId)

  if (!shouldCountMultipleUsage) {
    for (const discountCodePoolId in discountCodePoolUsageById) {
      discountCodePoolUsageById[discountCodePoolId] = 1
    }
  }

  return discountCodePoolUsageById
}
