import type { ProductVariant } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import type { UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useProductVariantById(
  variantId: number | undefined
): UseResource<ProductVariant.ProductVariantResp> {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.productVariantById, variantId],
    queryFn: () => api.getProductVariantByVariantId(variantId),
    enabled: !!variantId
  })

  return {
    data: data?.data,
    isLoading
  }
}
