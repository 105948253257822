import { useEffect, useState } from 'react'

export function useIsImageLoading(url: string | undefined): { isLoading: boolean } {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!url) {
      setIsLoading(false)
    } else {
      const image = new Image()
      image.src = url

      const interval = setInterval(function () {
        if (image.complete) {
          clearInterval(interval)
          setIsLoading(false)
        }
      }, 30)

      return () => {
        clearInterval(interval)
      }
    }
  }, [url])

  return { isLoading }
}
