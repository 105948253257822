import { type SMSMessageFlowItemUI } from '@ghostmonitor/recartapis'
import { validateSMSMessage } from '../../../../../../utils/validators/flow/validate-sms-message'
import { flowEditorSelectors } from '../../../../../selectors'
import { type DashboardState } from '../../../../../dashboard.state'
import { type ItemValidationRunner, type MessageValidationResult } from '../validator-factory'
import { validateKeywordReplyFlowItemMustHaveConnection } from '../../../../../../utils/validators/flow/validate-keyword-reply'

export function getSMSMessageValidationResult(
  flowItem: SMSMessageFlowItemUI,
  state: DashboardState
) {
  const result: MessageValidationResult = {
    succeeded: [],
    failed: []
  }
  const flowItemId = flowItem._id

  flowItem.item.messages.forEach((message, messageIndex) => {
    const variableTypeOptions = flowEditorSelectors.selectVariableTypeOptions(state)
    const entryVariableTypeOptions = flowEditorSelectors.selectEntryVariableTypeOptions(state) ?? []
    const linkTypeOptions = flowEditorSelectors.selectLinkTypeOptions(state)
    const entryLinkTypeOptions = flowEditorSelectors.selectEntryLinkTypeOptions(state) ?? []
    const flow = flowEditorSelectors.selectEditorFlow(state)
    const isEntryItem = flowItemId === flow?.entrySequenceItemId
    const hasShortCodeNumber = flowEditorSelectors.selectHasShortCode(state) ?? false

    const error = validateSMSMessage({
      entryLinkTypeOptions,
      entryVariableTypeOptions,
      isEntryItem,
      linkTypeOptions,
      message,
      variableTypeOptions,
      hasShortCodeNumber
    })

    if (error !== undefined) {
      result.failed.push({ flowItemId, messageIndex, errors: [error] })
    } else {
      result.succeeded.push({ flowItemId, messageIndex })
    }
  })

  return result
}

export function smsItemValidator(
  flowItem: SMSMessageFlowItemUI,
  state: DashboardState
): ItemValidationRunner {
  return {
    getConfiguredValidators: () => ({
      keywordReplyConnections: {
        validate: async () => {
          const error = validateKeywordReplyFlowItemMustHaveConnection(flowItem)
          return error !== undefined ? [error] : []
        }
      }
    })
  }
}
