import { type Selector } from 'reselect'
import { type SiteState } from '../store/slices/me/me.types'
import { useSite } from './resources/use-site'

export function useSiteSelector<T extends Selector<SiteState, any>>(
  selector: T
): ReturnType<T> | undefined {
  const { data: site } = useSite()

  return site ? selector(site) : undefined
}
