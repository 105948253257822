import Logger, { DatadogLogger } from '@ghostmonitor/lib-frontend-logger'
import isPlainObject from 'lodash/isPlainObject'
import LogRocket from 'logrocket'
import { config } from '../../config'
import { Notification } from '../notification/notification.util'

export const logger = new Logger()

const datadogLogger = new DatadogLogger({
  clientToken: config.DATADOG_CLIENT_TOKEN,
  service: 'core-client',
  environment: config.RECART_API_URL === 'https://api.recart.com' ? 'production' : 'staging',
  logLevel: 'info',
  forwardErrorsToLogs: true,
  beforeSend: (log) => {
    if (log.message.includes('ResizeObserver loop')) {
      return false
    }
  }
})

logger.addLogger(datadogLogger)

let globalContext = null

export function createScope(featureTag: string) {
  function getContext(...contextObjects: any[]): Record<string, unknown> {
    function getContextFromError(err: Error): Record<string, unknown> {
      return {
        message: err.message,
        ...(err.cause && { cause: getContext(err.cause) })
      }
    }

    function getContextFromPlainObject(obj: Record<string, unknown>): Record<string, unknown> {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value instanceof Error) {
          return {
            ...acc,
            [key]: getContextFromError(value)
          }
        }

        return {
          ...acc,
          [key]: value
        }
      }, {})
    }

    let context: Record<string, unknown> = {}

    contextObjects.forEach((contextObject) => {
      /********************************
       * Error object
       ********************************/
      if (contextObject instanceof Error) {
        context = {
          ...context,
          ...getContextFromError(contextObject)
        }

        /*******************************
         * Plain object, like { a: '1'}
         *******************************/
      } else if (isPlainObject(contextObject)) {
        context = {
          ...context,
          ...getContextFromPlainObject(contextObject)
        }

        /********************************
         * String
         ********************************/
      } else if (typeof contextObject === 'string') {
        context = {
          ...context,
          message: contextObject
        }
      }
    })

    return context
  }

  function getWithGlobalContext(...contextObjects: any[]): Record<string, unknown> {
    return {
      ...globalContext,
      feature: featureTag,
      LogRocketSessionUrl: LogRocket.sessionURL,
      ...getContext(...contextObjects)
    }
  }

  return {
    // It's exposed only for testing purposes
    getWithGlobalContext,
    developerError: (errorObject: Error, context?: { [key: string]: any }) => {
      if (config.NODE_ENV === 'development') {
        Notification.warn(errorObject.message, {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        })
        throw errorObject
      } else {
        logger.error(errorObject.message, getWithGlobalContext(errorObject, context))
      }
    },
    info: (message: string, context?: Record<string, unknown>) => {
      return logger.info(getWithGlobalContext(message, context))
    },
    error: (err: Error | string, context?: Record<string, unknown>) => {
      if (err instanceof Error) {
        logger.error(err.message, getWithGlobalContext(err, context))
      } else {
        logger.error(err, getWithGlobalContext({ message: err }, context))
      }
    }
  }
}

export function setGlobalContext(context) {
  globalContext = context
}

export function clearGlobalContext() {
  globalContext = null
}
