import { SequenceTag } from '@ghostmonitor/recartapis'
import { type SMSCampaignStatsById } from '../../types/sms-campaign-stats-by-id.type'
import { hooks } from '../hooks'
import { type UseResource } from '../types/use-resource.type'

export function useBlastsStat(): UseResource<SMSCampaignStatsById> {
  const { data: smsCampaigns, isLoading: isSMSCampaignsLoading } = hooks.useSMSCampaigns()
  const { data: sequencesStat, isLoading: isStatLoading } = hooks.useSequencesStat({
    isEnabled: true,
    tags: [SequenceTag.SMS, SequenceTag.CAMPAIGN]
  })

  const smsCampaignStatsById: SMSCampaignStatsById = smsCampaigns?.reduce((acc, blast) => {
    acc[blast._id] = sequencesStat?.[blast.sequenceId]
    return acc
  }, {})

  return {
    data: smsCampaignStatsById,
    isLoading: isSMSCampaignsLoading || isStatLoading
  }
}
