import { type HttpResponse } from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import { type FbMessengerConfig } from '../../types/facebook/facebook-messenger-config.type'
import { api } from '../../utils/api'
import { createScope } from '../../utils/logger/logger'
import { Notification } from '../../utils/notification/notification.util'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

const logger = createScope('dashboard')

async function remove() {
  await api.removeFacebookPage()
}

export function useFacebookPage(): UseResource<FbMessengerConfig | null> {
  const queryKey = useMemo(() => [QUERY_KEY.facebookPage], [])
  const { data, isLoading, isError } = useQuery({
    queryKey,
    queryFn: api.getFacebookPage,
    select: (response) => response.data
  })
  const queryClient = useQueryClient()

  const handleRemove = useCallback(() => {
    queryClient.cancelQueries(queryKey)
    const dataQuery = queryClient.getQueryCache().find(queryKey)!
    const previousData = cloneDeep(dataQuery.state.data as HttpResponse<FbMessengerConfig>)
    queryClient.setQueryData(queryKey, null)
    return () => queryClient.setQueryData(queryKey, previousData)
  }, [queryClient, queryKey])

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryKey)
    queryClient.invalidateQueries([QUERY_KEY.me])
  }, [queryClient, queryKey])

  const handleError = useCallback((err, _, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Disconnecting facebook page failed. Please try again or contact support.')
  }, [])

  const { mutateAsync: removeFacebookPage } = useMutation<void, unknown, unknown>({
    mutationFn: remove,
    onMutate: handleRemove,
    onSettled: handleSettled,
    onError: handleError
  })

  return {
    data,
    isLoading,
    isError,
    removeFromList: removeFacebookPage
  }
}
