import {
  type GetSmsSubscriberTimezoneOffsetsResponse,
  type TimezoneOffset
} from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

interface Params {
  dateTime: string
}

export function useSMSSubscriberTimezoneOffsets(
  queryParams?: Params
): UseResource<TimezoneOffset[]> {
  const queryKey = [QUERY_KEY.smsSubscriberTimezoneOffsets, queryParams]

  const {
    isLoading,
    data: timezoneOffsets,
    isError
  } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<GetSmsSubscriberTimezoneOffsetsResponse>(
        '/subscribers/sms-subscribers/timezone-offsets',
        { params: queryParams }
      )
    }
  })

  return {
    data: timezoneOffsets?.data ?? [],
    isLoading,
    isError
  }
}
