import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'
import { type SalesStackedDiagramResponse } from './use-sales-stacked-chart'

export interface SiteSalesStatistics {
  facebook: number
  sms: number
  email?: number
}

export function useSiteTotalSalesStatistics(): UseResource<SiteSalesStatistics> {
  const queryKey = [QUERY_KEY.salesStackedChart]

  const { isInitialLoading, data } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<SalesStackedDiagramResponse>('statistics/sales')
    },
    select: (response) => response.data
  })

  const totalSalesStats = {
    facebook: data?.facebook?.total ?? 0,
    sms: data?.sms?.total ?? 0,
    email: data?.email?.total ?? 0
  }

  return {
    data: totalSalesStats,
    isLoading: isInitialLoading
  }
}
