import { type SMSSettings, type SMSSettingsService } from '@ghostmonitor/recartapis'
import { type SerializedError } from '@reduxjs/toolkit'
import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export type PatchSMSQuietHoursSettingRequest = Omit<
  SMSSettingsService.UpsertQuietHoursSettingsRequest,
  'siteId'
>

export function useSMSQuietHoursSettings(
  options?: UseQueryOptions<SMSSettingsService.GetQuietHoursSettingsResponse>
): UseResource<SMSSettings.QuietHoursConfig> {
  const queryKey = [QUERY_KEY.smsQuietHoursSettings]

  const {
    isLoading,
    data,
    error: requestError
  } = useQuery({
    queryKey,
    queryFn: api.getSMSQuietHoursSettings,
    ...options
  })
  const error: SerializedError = requestError

  return {
    data: data?.data,
    error,
    isLoading
  }
}
