import {
  type SubscriptionSourcesStat,
  type SubscriptionSourcesStatResponse
} from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

export function useSubscriptionSourcesStat(): UseResource<SubscriptionSourcesStat> {
  const queryKey = [QUERY_KEY.subscriptionSourcesStat]

  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)

  const queryParams: {
    startDate?: string
    endDate?: string
  } = {}

  if (dateFrom) {
    const startDate = dateFrom.toISOString()
    queryParams.startDate = startDate
    queryKey.push(startDate)
  }

  if (dateTo) {
    const endDate = dateTo.toISOString()
    queryParams.endDate = endDate
    queryKey.push(endDate)
  }

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: () =>
      request.get<SubscriptionSourcesStatResponse>('statistics/subscription-sources', {
        params: queryParams
      }),
    select: (response) => response.data,
    enabled: dateFrom !== undefined && dateTo !== undefined
  })

  return {
    data,
    isLoading
  }
}
