import type { BillingInfo, SiteEngineSlug } from '@ghostmonitor/recartapis'
import { getUsedSiteEngines } from '../../../utils/utils'
import { type SiteState } from '../me/me.types'

export const selectCurrencySlug = (state: SiteState) => state.currency
export const selectShopify = (state: SiteState) => state.shopify
export const selectSiteCreatedAt = (state: SiteState) => state.createdAt
export const selectSiteId = (state: SiteState) => state.id
export const selectSiteName = (state: SiteState) => state.name
export const selectSiteUrl = (state: SiteState) => state.domain
export const selectStripeCustomer = (state: SiteState) => state.stripe?.customer
export const selectStripeSubscriptions = (state: SiteState) => state.stripe?.subscriptions
export const selectUrlGenerationSettings = (state: SiteState) => state.urlGenerationSettings
export const selectSupportEmail = (state: SiteState) => state.supportEmail
export const selectTimeZone = (state: SiteState) => state.timeZone
export const selectTrialEndsAt = (state: SiteState) => state.trialEndsAt
export const selectAISettingsPersonas = (state: SiteState) =>
  state.aiSettings?.textGeneration.prompt.segments
export const selectSiteBillingInfo = (state: SiteState): BillingInfo | undefined =>
  state.billingInfo

const siteEngines = getUsedSiteEngines()
export const selectSiteEngineSlug = (state: SiteState): SiteEngineSlug | undefined => {
  return siteEngines.find((engine) => engine.id === state?.engine)?.slug
}
