import { type HttpResponse } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { QUERY_KEY } from './query-keys'

// NOTE: prefer using useOptinToolsList
export function useOptinTools<T>({ type }: { type: string }): UseResource<T[]> {
  const queryKey = [QUERY_KEY.optinTool]

  const queryParams: {
    type?: string
  } = {}

  if (type) {
    queryParams.type = type
  }

  const { isLoading, data } = useQuery({
    queryKey,
    queryFn: async () =>
      request.get<HttpResponse<T[]>>(`optin-tools`, {
        params: queryParams
      }),
    select: (response) => response.data
  })

  return {
    isLoading,
    data
  }
}
