import {
  type CreateDiscountCodePoolRequest,
  type CreateDiscountCodePoolResponse,
  type DiscountCodePool,
  type GetDiscountCodePoolsResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient, type UseQueryOptions } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { type UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_KEY } from './query-keys'
import { type GetDiscountCodePoolsRequest } from '@ghostmonitor/recartapis/clients/ts/storetracking/discount_service'

export type CreateDiscountCodePoolRequestUI = Omit<CreateDiscountCodePoolRequest, 'siteId'>
export type GetDiscountCodePoolRequestUI = Partial<Omit<GetDiscountCodePoolsRequest, 'siteId'>>

export function useDiscountCodePools(
  options?: UseQueryOptions<GetDiscountCodePoolsResponse>,
  additionalQueryParams?: GetDiscountCodePoolRequestUI
): UseResource<DiscountCodePool[]> & { isLoading: boolean } {
  const queryKey = [QUERY_KEY.discountCodePools, additionalQueryParams]
  const queryClient = useQueryClient()
  const { handleError } = useMutationHelpers(queryKey)

  const {
    isLoading,
    data: discountCodePools,
    isError,
    refetch
  } = useQuery({
    queryKey,
    queryFn: async () => {
      return request.get<GetDiscountCodePoolsResponse>('discount-code-pools', {
        ...(additionalQueryParams ? { params: additionalQueryParams } : {})
      })
    },
    ...options
  })

  async function createDiscountCodePool(
    discountCodePoolRequest: CreateDiscountCodePoolRequestUI
  ): Promise<CreateDiscountCodePoolResponse> {
    return request.post<CreateDiscountCodePoolResponse>(
      'discount-code-pools',
      discountCodePoolRequest
    )
  }

  const { mutateAsync: mutateCreateDiscountCode } = useMutation<
    CreateDiscountCodePoolResponse,
    unknown,
    CreateDiscountCodePoolRequestUI
  >({
    mutationFn: createDiscountCodePool,
    onError: handleError,
    onSuccess: (createDiscountPoolResponse: CreateDiscountCodePoolResponse) => {
      const discountCodePools: GetDiscountCodePoolsResponse = queryClient.getQueryData(queryKey)
      if (discountCodePools) {
        const newDiscountCodePools = {
          data: [...discountCodePools.data, createDiscountPoolResponse.data]
        }

        queryClient.setQueryData(queryKey, newDiscountCodePools)
        queryClient.invalidateQueries(queryKey)
      }
    }
  })

  return {
    data: discountCodePools?.data,
    refetch: refetch as any,
    isLoading: isLoading || discountCodePools === undefined,
    isError,
    create: mutateCreateDiscountCode
  }
}
